// Borrowed from here:
// https://github.com/gorails-screencasts/423-stimulus-values-api-defaults/blob/master/app/javascript/controllers/countdown_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "hidden", "warning", "expired" ]
  static targets = [ "hideWhenExpired", "countdown" ]
  
  static values = {
    date: String,
    warningInterval: { type: Number, default: 60 },
    refreshInterval: { type: Number, default: 1000 },
    expiredMessage: { type: String, default: "Ending now..." },
    message: { type: String, default: "${minutes}m ${seconds}s" }
  }

  connect() {
    this.warningClassApplied = false
    
    if (this.hasDateValue) {
      this.endTime = new Date(this.dateValue).getTime()

      this.update()
      this.timer = setInterval(() => {
        this.update()
      }, this.refreshIntervalValue)
    } else {
      console.error("Missing data-countdown-date-value attribute", this.element)
    }
  }

  disconnect() {
    this.stopTimer()
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  update() {
    const difference = this.timeDifference()
    
    if (this.hasWarningClass && (difference <= this.warningIntervalValue*1000) && (difference > 0) && !this.warningClassApplied) {
      this.element.classList.add(this.warningClass)
      this.warningClassApplied = true
    }

    if (difference < 0) {
      if (this.warningClassApplied) {
        this.element.classList.remove(this.warningClass)
        this.warningClassApplied = false
      }
      
      if (this.hasExpiredClass) {
        this.element.classList.add(this.expiredClass)
      }
      
      this.countdownTarget.textContent = this.expiredMessageValue
      this.stopTimer()
      
      if (this.hasHideWhenExpiredTarget) {
        this.hideHideTargets()
      }
      
      return
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24))
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    this.countdownTarget.textContent = this.messageValue
      .replace("${days}", days)
      .replace("${hours}", hours)
      .replace("${minutes}", minutes)
      .replace("${seconds}", seconds)
  }

  timeDifference() {
    return this.endTime - new Date().getTime()
  }
  
  hideHideTargets() {
    this.hideWhenExpiredTargets.forEach((hideTarget) => {
      hideTarget.classList.add(this.hiddenClass)
    })
  }
}